// color palette
$red: #0d6beb;
$blue: #ed1f34;
$dark-grey: #272727;
$light-grey: #f5f5f5;
$text-color: #8d8d8d;

//fonts
$heading-regular: 'regular_vig-font';
$heading-light: 'light_vig-font';
$heading-bold: 'bold_vig-font';

$text-default: 'raleway';

//breakpoints
$break-extra-small: 300px;
$break-small: 480px;
$break-medium: 700px;
$break-big: 1280px;
$break-giant: 1400px;

//hamburger
$height-icon: 25px;
$width-line: 40px;
$height-line: 5px;

$transition-time: 0.4s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;

// header-default styles
@import "../../_styles/_variables.sass";

.logo {
  display: inline-block;
  position: relative;
  z-index: 2;
  margin-top: 10px;
  img {
    width: 165px;
    height: auto; } }

.hero {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  min-height: 687px;

  @media screen and (max-width: $break-medium) {
    min-height: 900px; }
  @media screen and (max-width: $break-small) {
    min-height: 687px; }
  .hero-text {
    color: $dark-grey;
    font-family: $heading-light;
    font-size: 92px;
    line-height: 0.85;
    position: absolute;
    margin-left: 20px;
    z-index: 2;
    top: 30%;
    transform: translateY(-30%);

    @media screen and (max-width: $break-medium) {
      font-size: 60px; }
    @media screen and (max-width: $break-small) {
      font-size: 40px; }

    .blue {
      color: $red;
      font-family: $heading-bold;
      font-size: 165px;
      @media screen and (max-width: $break-medium) {
        font-size: 107px; }
      @media screen and (max-width: $break-small) {
        font-size: 71px; } }
    .red {
      color: $blue;
      font-family: $heading-bold;
      font-size: 125px;
      @media screen and (max-width: $break-medium) {
        font-size: 87px; }
      @media screen and (max-width: $break-small) {
        font-size: 61px; } }
    .bold {
      font-family: $heading-bold {
        font-size: 92px; }
      @media screen and (max-width: $break-medium) {
        font-size: 60px; }
      @media screen and (max-width: $break-small) {
        font-size: 40px; } } }
  .logos {
    position: absolute;
    bottom: 10%;
    width: 100%;
    z-index: 2; }

  .hero-image {
    position: absolute;
    width: auto;
    height: auto;
    opacity: 0.4;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%; }

  .nav {
    display: inline-block;
    margin-top: 60px;
    float: right;

    @media screen and (max-width: $break-medium) {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0; }

    .menu {
      display: block;
      position: relative;
      z-index: 2;
      @media screen and (max-width: $break-medium) {
        display: none; }
      li {
        display: inline-block;
        margin-left: 30px;
        list-style: none;

        a {
          font-family: $heading-bold;
          font-size: 30px;
          color: $dark-grey;
          text-decoration: none;
          padding: 10px;
          opacity: 0.7;
          transition: opacity .1s; }
        a:hover {
          opacity: 1;
          background-image: url('../../images/menu-bg.png');
          background-repeat: no-repeat;
          background-position: 10px 90%; } } }

    .visible {
      position: absolute;
      display: block !important;
      margin-left: 0;
      width: 100%;
      z-index: 99;
      top: 0;
      left: 0;

      ul {
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 100px;
        left: 0;
        -webkit-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
        -moz-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
        box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
        background-color: $red; }

      li {
        width: 100%;
        text-align: center;
        background-color: $red;
        display: block;
        padding: 15px 20px;
        margin: 0 auto;
        border-bottom: 2px solid rgba(255,255,255,0.3);

        a {
          &:active, &:hover {
            color: #fff !important; } } } } } }


.steinhardt, .wey {
  text-align: center;
  width: 100%;
  display: table;
  position: relative;

  .image {
    display: table-header-group; }
  img {
    top: 0;
    margin: 0 auto;
    max-width: 100%;
    max-height: 120px;
    height: auto;
    filter: grayscale(1);
    transition: filter 0.3s ease-in-out;
    @media screen and (max-width: $break-small) {
      width: 100%; } }

  img:hover {
    filter: none; }

  button {
    &:hover {
      & ~ .image {
        img {
          filter: none; } } } } }

.parallax-window {
  position: absolute;
  height: 100%;
  background: transparent;
  width: 100%;
  top: 0; }

// MOBILE

#hamburger-icon {
  margin-top: 30px;
  float: right;
  width: $width-line;
  height: $height-icon;
  position: relative;
  display: none;
  z-index: 99;
  padding: 0;
  background-image: none;

  @media screen and (max-width: $break-medium) {
    display: block !important; }

  .line {
    display: block;
    background: $dark-grey;
    width: $width-line;
    height: $height-line;
    position: absolute;
    left: 0;
    border-radius: ($height-line / 2);
    transition: all $transition-time;
    -webkit-transition: all $transition-time;
    -moz-transition: all $transition-time;

    &.line-1 {
      top: 0; }

    &.line-2 {
      top: 50%;
      margin-left: -10px; }

    &.line-3 {
      top: 100%; } }


  &:hover, &:focus {
    .line-1 {
      transform: translateY($height-line / 2 * -1);
      -webkit-transform: translateY($height-line / 2 * -1);
      -moz-transform: translateY($height-line / 2 * -1); }

    .line-2 {
      margin-left: 0px; }

    .line-3 {
      transform: translateY($height-line / 2);
      -webkit-transform: translateY($height-line / 2);
      -moz-transform: translateY($height-line / 2); } }


  &.active {
    .line-1 {
      transform: translateY($translateY) translateX($translateX) rotate($rotation);
      -webkit-transform: translateY($translateY) translateX($translateX) rotate($rotation);
      -moz-transform: translateY($translateY) translateX($translateX) rotate($rotation);
      background-color: $red; }

    .line-2 {
      opacity: 0; }

    .line-3 {
      transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
      -webkit-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
      -moz-transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
      background-color: $red; } } }

.bottom-text {
  font-size: 34px;
  bottom: 20px;
  position: absolute;
  padding-left: 20px;
  font-family: 'regular_vig-font';
  z-index: 3;
  span {
    color: $red; } }

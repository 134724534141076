// Normalize Styles
@import "node_modules/normalize.css/normalize";

// Import Modules
@import "../_modules/header-default/header-default";
@import "_variables.sass";
@import "toast.scss";
@import "fonts.scss";

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0; }
* {
  &::-moz-selection /* Code for Firefox */ {
    color: white;
    background: $red; }


  &::selection {
    color: white;
    background: $red; } }


body {
  margin: 0; }

.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%; }

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0; }

.container {
  max-width: 1300px;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 10px;

  @media screen and (max-width: $break-small) {
    padding: 0px; } }

.row {
  margin-left: 20px;
  margin-right: 20px;
  @media screen and (max-width: $break-small) {
    margin-left: 0px;
    margin-right: 0px; } }

h1, h2, h3, h4, h5 {
  font-family: $heading-bold; }

h2 {
  font-size: 48px; }

section {
  padding: 85px 0;

  h2 {
    line-height: 130px; }
  p {
    font-family: $text-default;
    font-size: 18px;
    line-height: 36px;
    color: $text-color;
    padding-bottom: 30px; }

  .bg-title {
    position: absolute;
    text-align: center;
    margin-top: -105px;
    margin-left: -1950px /*-x*700+x*30 margin-left and width need to have the same proportions in order to be on the same position*/;
    width: 4200px /*x*1400*/;
    -webkit-touch-callout: none /* iOS Safari */;
    -webkit-user-select: none   /* Chrome/Safari/Opera */;
    -khtml-user-select: none   /* Konqueror */;
    -moz-user-select: none     /* Firefox */;
    -ms-user-select: none       /* IE/Edge */;
    user-select: none;
    h4 {
      font-size: 430px;
      color: $light-grey; } }
  .image-title {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -150px;
    margin-bottom: -40px;
    @media screen and (max-width: $break-big) {
      margin-right: 0px; }
    h4 {
      font-size: 150px;
      color: $red;
      @media screen and (max-width: $break-big) {
        font-size: 100px;
        margin-bottom: 20px; }
      @media screen and (max-width: $break-medium) {
        font-size: 150px;
        margin-bottom: -40px; }
      @media screen and (max-width: $break-small) {
        font-size: 80px;
        margin-bottom: 0px; } } } }
.section-img {
  width: 100%;
  margin: 35px;
  -webkit-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
  box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
  @media screen and (max-width: $break-medium) {
    margin: 55px 0 0 0; } }
.section-img-big {
  display: block;
  margin: 35px auto;
  -webkit-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
  box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
  @media screen and (max-width: $break-medium) {
    margin: 55px auto 0 auto; } }
button {
  padding: 12px 30px;
  min-width: 170px;
  background-color: transparent;
  transition: all 0.1s ease-in;
  font-family: $heading-bold;
  font-size: 20px;

  @media screen and (max-width: $break-medium) {
    font-size: 18px;
    padding: 9px 24px;
    min-width: 130px; }

  &:focus {
    outline: none; }

  &.dark {
    border: 2px solid $dark-grey;
    color: $dark-grey;
    &:hover {
      background-color: $dark-grey;
      color: #fff; } }

  &.red {
    border: 2px solid $red;
    color: $red;
    &:hover {
      background-color: $red;
      color: #fff; } } }
.map-container {
  height: 70vh;
  background-color: #ebebeb;
  position: relative;

  button {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99;
    margin-top: 50px;
    transform: translate(-50%, -50%); } }
#map {
  height: 100%;
  width: 100%; }

.sidebar {
  ul {
    @media screen and (max-width: $break-medium) {
      max-height: 400px;
      overflow-y: scroll;
      display: none;
      -webkit-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
      -moz-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
      box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
      margin-bottom: 80px;
      background-color: #fff; } }
  li {
    list-style: none;
    font-family: $text-default;
    padding: 11px 0;

    @media screen and (max-width: $break-medium) {
      text-align: center;
      position: relative;
      padding: 20px;
      border-bottom: 1px solid #ebebeb;
      width: 100%;
      font-size: 20px; }
    .active {
      color: $red; }

    a {
      color: $text-color;
      transition: all .3s;
      text-decoration: none;
      &:hover {
        color: $red;
        border-left: 3px solid $red;
        padding-left: 12px; } } }
  .mobile {
    text-align: center; } }
.item-box {
  width: 100%;
  position: relative;
  -webkit-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
  box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.35);
  min-height: 200px;
  margin-top: 30px;
  margin-bottom: 50px;
  background-color: #fff;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.02); }

  .item-image {
    display: inline-block;
    position: absolute;
    top: -50px;
    left: -30px;

    @media screen and (max-width: $break-medium) {
      left: 50%;
      transform: translate(-50%, 0%); } }
  h2 {
    padding-top: 30px;
    line-height: 70px;
    font-family: $heading-bold;

    @media screen and (max-width: $break-medium) {
      padding-top: 160px; } }

  h2, p {
    margin-left: 170px;
    margin-right: 30px;

    @media screen and (max-width: $break-medium) {
      margin: 0 30px;
      text-align: center; } }

  .attachments-footer {
    padding: 0 30px 30px 30px;

    @media screen and (max-width: $break-big) {
      text-align: center; }

    button {
      margin-right: 15px;
      margin-top: 10px;
      display: inline-block;

      @media screen and (max-width: $break-medium) {
        width: 100%; } } } }
.footer {
  font-family: $text-default;
  margin-top: -50px;
  margin-bottom: 50px;

  @media screen and (max-width: $break-small) {
    .links, .right {
      display: none !important; } }

  .links {
    list-style: none;
    display: inline-block;
    li:last-child {
      border-right: none; }

    li {
      display: inline-block;
      padding: 0 12px;
      border-right: 1px solid $text-color;

      a {
        color: $text-color;
        text-decoration: none;

        &:hover {
          color: $red;
          text-decoration: underline; } } } }

  .left {
    float: left;
    display: inline-block;
    color: $text-color; }
  .right {
    float: right;
    color: $text-color;
    font-size: 18px;
    position: relative;
    top: 1px; }

  .zanaro-quality-mark-hover {
    position: absolute;
    right: -10px;
    top: -13px;
    margin-right: 20px;
    background-color: $light-grey;
    padding: 10px;
    color: $text-color;
    display: none;

    a {
      text-decoration: none;
      color: $text-color;

      &:visited {
        color: $text-color; }

      .name {
        color: $dark-grey;
        text-transform: uppercase;
        font-family: $heading-regular;
        font-size: 22px;
        vertical-align: middle; }

      span {
        color: $dark-grey; }
      .ico-zanaro-quality {
        font-size: 18px;
        position: relative;
        top: 3px; } } } }

.desktop {
  display: block;
  @media screen and (max-width: $break-medium) {
    display: none; } }
.mobile {
  display: none;
  @media screen and (max-width: $break-medium) {
    display: block; } }

#loadingImg {
  position: absolute;
  z-index: 99;
  background: rgba(255,255,255,0.3);
  width: 100%;
  height: 100%;

  .stroke {
    position: relative;
    display: inline-block;
    top: 50%;
    left: 50%;
    background-color: #fff;
    padding: 10px;
    border-radius: 200px;
    transform: translate(-50%, -50%);
    height: 120px;
    width: 120px;
    -webkit-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 95px 0px rgba(0,0,0,0.1); }
  img {
    position: relative;
    top: 50%;
    left: 50%;
    width: 140px;
    -webkit-filter: drop-shadow(12px 12px 25px rgba(0,0,0,0.2));
    filter: url(#drop-shadow);
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
    transform: translate(-50%, -50%); } }

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on March 9, 2016 */


@font-face {
  font-family: 'regular_vig-font';
  src: url('../fonts/regular_vig-font.eot?#iefix') format('embedded-opentype'),  url('../fonts/regular_vig-font.otf')  format('opentype'),
  url('../fonts/regular_vig-font.woff') format('woff'), url('../fonts/regular_vig-font.ttf')  format('truetype'), url('../fonts/regular_vig-font.svg#regular_vig-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'light_vig-font';
  src: url('../fonts/light_vig-font.eot?#iefix') format('embedded-opentype'),  url('../fonts/light_vig-font.otf')  format('opentype'),
  url('../fonts/light_vig-font.woff') format('woff'), url('../fonts/light_vig-font.ttf')  format('truetype'), url('../fonts/light_vig-font.svg#light_vig-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bold_vig-font';
  src: url('../fonts/bold_vig-font.eot?#iefix') format('embedded-opentype'),  url('../fonts/bold_vig-font.otf')  format('opentype'),
  url('../fonts/bold_vig-font.woff') format('woff'), url('../fonts/bold_vig-font.ttf')  format('truetype'), url('../fonts/bold_vig-font.svg#bold_vig-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

// icomoon

@font-face {
  font-family: 'emr-icons';
  src:    url('../fonts/emr-icons.eot?d2cmz3');
  src:    url('../fonts/emr-icons.eot?d2cmz3#iefix') format('embedded-opentype'),
  url('../fonts/emr-icons.ttf?d2cmz3') format('truetype'),
  url('../fonts/emr-icons.woff?d2cmz3') format('woff'),
  url('../fonts/emr-icons.svg?d2cmz3#emr-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'emr-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-tooltip:before {
  content: "\e903";
}
.ico-tooltip-active:before {
  content: "\e904";
}
.ico-envelope:before {
  content: "\e900";
}
.ico-zanaro-quality:before {
  content: "\e901";
}
.ico-phone:before {
  content: "\e902";
}

